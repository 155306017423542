<template>
    <div>
        <v-card >
            <v-card-title class="blue-grey white--text">
                <v-row>
                    <v-col cols="6" md="6">VENTA</v-col>
                    <v-col cols="6" md="6" class="text-end">
                        <h5 class="text-caption white--text">Bodega: {{ txtSucursal }}</h5>
                        <h5 class="text-caption white--text">Dirección Tributaria: {{ txtOffice }}</h5>
                    </v-col>
                    <!-- <v-col cols="6" md="6">
                        <v-row class="mt-0 mr-2" justify="end">
                            <span class="text-caption"><v-chip color="white"><strong class="text-body-2">{{txtSucursal}}</strong></v-chip></span>
                        </v-row>
                    </v-col> -->
                </v-row>
            </v-card-title>
            <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="8" md="2">
                                <v-text-field class='text-caption' v-model="orden" label="Numero Cotiza" :disabled="disabledOrden" :filled="filledOrden" outlined dense required></v-text-field>
                            </v-col>
                            <v-col cols="4" md="1">
                                <v-btn @click="verOrden(orden)" class="mt-1" small color="teal" dark rounded><v-icon small>search</v-icon></v-btn>
                            </v-col>
                            <v-col cols="8" md="2">
                                <v-text-field class='text-caption' v-model="rut" label="Rut Cliente" dense outlined filled disabled></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field class='text-caption' v-model="razon" label="Nombre" dense outlined filled disabled></v-text-field>
                            </v-col>
                            <v-col cols="8" md="3" class="mt-2 text-body-2 grey--text text-center">
                                <span v-if="autoriza=='SI'">Credito Autorizado {{ credito_dias }} Dias</span>
                            </v-col>
                        </v-row>
                        <v-divider style="width:0px"></v-divider>
                        <v-row>
                            <v-col cols="12" md="9">
                                <v-data-table :headers="headers" :items="items" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size col-color" @page-count="pageCount = $event">
                                    <template v-slot:item.stock="{ item }">
                                        <div v-if="item.stock=='si'">
                                            <v-icon small class="text green--text">check</v-icon>
                                        </div>
                                        <div v-if="item.stock=='no'">
                                            <v-icon small class="ml-5 text red--text">clear</v-icon>
                                            <v-tooltip left>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on" small class="ml-2 text cyan--text">help</v-icon>
                                                </template>
                                                <span class="text-caption">El stock de este producto es insuficiente para este local</span>
                                            </v-tooltip>
                                        </div>
                                    </template>
                                </v-data-table>
                                <v-pagination v-model="page" :length="pageCount"></v-pagination>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-alert class='text-caption' dense text border="left" color="grey">
                                    <div class="float-left">
                                        Neto
                                    </div>
                                    <div class="float-right">
                                        ${{new Intl.NumberFormat("de-DE").format(parseFloat(neto))}}
                                    </div>                                             
                                </v-alert>
                                <v-alert class='mt-n2 text-caption' dense text border="left" color="grey">
                                    <div class="float-left">
                                        Iva ({{impuesto}}%)
                                    </div>
                                    <div class="float-right">
                                        ${{new Intl.NumberFormat("de-DE").format(parseFloat(iva))}}
                                    </div>                                             
                                </v-alert>
                                <!-- <v-alert class='mt-n2 text-caption' dense text border="left" color="grey">
                                    <div class="float-left">
                                        Ajuste
                                    </div>
                                    <div class="float-right">
                                        ${{new Intl.NumberFormat("de-DE").format(parseFloat(ajuste))}}
                                    </div>                                             
                                </v-alert> -->
                                <v-alert style="height:40px;" class='mt-n2 text-body-2' dense text border="left" color="red">
                                    <div class="mt-1 float-left">
                                        Total
                                    </div>
                                    <div class="mt-1 float-right">
                                        ${{new Intl.NumberFormat("de-DE").format(parseFloat(total))}}
                                    </div>                                             
                                </v-alert>
                                <!-- <v-alert class='text-caption' dense text border="left" color="grey">Neto ($):{{neto.toLocaleString()}}</v-alert> 
                                <v-alert class='text-caption' dense text border="left" color="grey">Iva ({{impuesto}}%): {{iva}}</v-alert>
                                <v-alert class='text-caption' dense text border="left" color="grey">Ajuste ({{impuesto}}%): {{iva}}</v-alert>
                                <v-alert class='text-caption' dense text border="left" color="red">Total ($): {{total.toLocaleString()}}</v-alert> -->
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="2">
                                <v-select class='text-caption' v-model="dte" :items="itemDTE" label="Tipo Documento" item-text="doc" item-value="id"></v-select>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-select @change="cambiaForma()" class='ml-2 text-caption' v-model="pago" :items="itemPAGO" label="Forma Pago" item-text="forma" item-value="id"></v-select>
                            </v-col>
                            <v-col cols="12" md="2">
                                <div v-if="parseInt(pago)!=4">
                                    <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field class="mt-3" v-model="vencimiento" label="Vencimiento" prepend-icon="event" readonly v-bind="attrs" v-on="on" dense outlined></v-text-field>
                                        </template>
                                        <v-date-picker v-model="vencimiento" @input="menu1 = false"></v-date-picker>
                                    </v-menu>
                                </div>
                                <div v-else>
                                    <div v-if="parseInt(credito_dias)!=0">
                                        <v-text-field class="mt-3" v-model="vencimiento" label="Vencimiento" prepend-icon="event" dense outlined disabled filled></v-text-field>
                                    </div>
                                    <div v-else>
                                        <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field class="mt-3" v-model="vencimiento" label="Vencimiento" prepend-icon="event" readonly v-bind="attrs" v-on="on" dense outlined></v-text-field>
                                            </template>
                                            <v-date-picker v-model="vencimiento" @input="menu1 = false"></v-date-picker>
                                        </v-menu>
                                    </div>
                                </div>
                            </v-col>
                             <v-col cols="12" md="3">
                                <v-text-field class='text-caption' v-model="envio" label="Envio Digital" dense filled></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <div class="mt-5 text-right">
                                    <v-btn @click="newVenta()" small color="teal" dark rounded><v-icon small>add_circle</v-icon><span class='text-btn'>Nuevo</span></v-btn>
                                    <!-- <v-btn v-if="btnGrabar===true" @click="outDTE(dte,pago)" class="ml-2" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn> -->
                                    <v-btn v-if="btnGrabar===true" @click="enviarDTE(dte,pago)" class="ml-2" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Enviar DTE</span></v-btn>
                                    <v-btn v-else class="ml-2" small color="grey" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Enviar DTE</span></v-btn>
                                    <a  v-if="btnPrt===true" target="_blank" :href="html" class="ml-2"><v-icon color="teal">print</v-icon></a>
                                    <a  v-else href="javascript:void(0)" class="ml-3"><v-icon class="grey--text">print</v-icon></a>
                                    <!-- <a  class="ml-2" v-if="descargaCSV===false" href="javascript:void(0)" @click="fileCSV()"><img valign="middle" src="@/assets/csv.png" width="16px" height="20px"/></a>
                                    <a  class="ml-2" v-else :href="rutaCSV"><v-icon color="teal">cloud_download</v-icon></a> -->
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
            </v-card-text>
            <v-card-actions style="margin-top:-35px">
            </v-card-actions>
        </v-card>
        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"  /> 
        <!-- dialogo warning de stock -->
         <v-dialog v-model="d_stock" width="300">
            <v-card>
                <v-card-title>
                    <v-icon color="warning">warning</v-icon>
                </v-card-title>
                <v-card-text>
                    <span class="mt-2 text-body-2">El usuario ADMIN no verifica stock !!!</span> 
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="d_stock=false" color="error" small rounded>cerrar</v-btn>
                </v-card-actions>
            </v-card>
         </v-dialog>
        <!-- dialogo Guia de Despacho -->
        <v-dialog v-model="d_guia" width="320">
            <v-card>
                <v-card-title>
                    <v-icon color="warning">warning</v-icon>
                </v-card-title>
                <v-card-text>
                    <span class="mt-2 text-body-2">Orden con Guia de Despacho: N° <strong>{{ arrayGuia.Numero }}</strong></span>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="d_guia=false" color="error" small rounded>cerrar</v-btn>
                </v-card-actions>
            </v-card>
         </v-dialog>
    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 9pt !important;
    height: 25px !important;
    /*background-color: lightyellow;*/ 
}
.col-color >>> th {
    background-color: lightgrey;
}
</style>
<script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import axios from 'axios';
export default {
    name:'Venta', 
    components:{dialogo, snack},
    props: ['usu','contrato'],   
    data(){
        return{
            //contrato:'123',
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            disabledOrden:false,
            filledOrden:false,
            btnGrabar:false,
            btnPrt:false,
            html:'',
            orden:'',
            entidad:'',
            rut:'',
            razon:'',
            direccion:'',
            comuna:'',
            ciudad:'',
            giro:'',
            fecha:'',
            hoy:'',
            neto:0,
            impuesto:'',
            iva:0,
            ajuste:0,
            total:0,
            dte:'',
            pago:'',
            page: 1,
            pageCount: 0,
            itemsPerPage: 4,
            headers: [
                { text: 'Codigo', align: 'start', sortable: true, value: 'codigo' },
                { text: 'Producto', align: 'start', sortable: false, value: 'producto' },
               // { text: 'Neto', align: 'center', sortable: false, value: 'neto' },
                { text: 'Precio', align: 'center', sortable: false, value: 'precio' },
                { text: 'Cantidad', align: 'center', sortable: false, value: 'cantidad' },
                { text: 'Medida', align: 'center', sortable: false, value: 'medida' },
                //{ text: 'Bodega', align: 'center', sortable: false, value: 'bodega' },
                { text: 'Stock', align: 'center', sortable: false, value: 'stock' },
            ],
            items: [],
            itemDTE:[
                {id:'22',doc:'Boleta'},
                {id:'5',doc:'Factura'},
            ],
            itemPAGO:[],
            OutID:0,
            descargaCSV:false,
            rutaCSV:'',   
            envio:'',
            nStock:0,
            txtStock:'si',
            nSucursal:0,
            txtSucursal:'',
            menu1:false,
            vencimiento:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10),
            idLocal:0,

            skuStock:[],
            skuTotal:0,
            autoriza:'NO',
            credito_dias:'0',
            observa_cot:'',

            d_stock:false,

            conGuia:'no',
            arrayGuia:[],
            d_guia:false,

            office:1,
            itemsucursal:[],
            txtOffice:'',

        }
    },
    async mounted(){
        await this.buscaSucursal();
        await this.buscaLocal();
        await this.newVenta();
        await this.cargaPagos('1-9');
    },
    methods:{
        async buscaSucursal(){
            const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/sucursales/'+this.contrato);
            const datos=response.data;
            this.itemsucursal=[];
            if (datos[0].existe=='si'){
                datos.forEach(element => {
                    this.itemsucursal.push({"code":element.Oficina,"name":element.Nombre})
                });
            }
        },
        cambiaForma(){
            if(parseInt(this.pago)==4){
                //sumar dias del credito
                var fecha = new Date();
                var dias = parseInt(this.credito_dias);
                fecha.setDate(fecha.getDate() + dias);
                const new_fecha = (new Date(fecha - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
                this.vencimiento=new_fecha
            } else {
                this.vencimiento=(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
            }
        },
        async buscaLocal(){
            try {
                const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/usuarios/'+this.contrato+'/'+this.usu);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    this.idLocal=datos[0].Bodega;
                    this.nSucursal=this.idLocal;
                    this.office = datos[0].Sucursal;
                    const sucu=await this.buscarSucursal_v2();
                    this.txtOffice='';
                    const filter = this.itemsucursal.filter(res => parseInt(res.code) == parseInt(this.office));
                    if(filter.length!=0){ this.txtOffice = filter[0].name};
                    if(sucu!=''){
                        this.txtSucursal=sucu;
                    } else {
                        this.txtSucursal=this.nSucursal;
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },
        async cargaPagos(xRut){
            try {
                var auth='NO';
                const res = await axios.get(process.env.VUE_APP_URL + '/api/inv/clientes/'+this.contrato+'/'+ xRut);
                const dat=res.data;
                if (dat[0].existe=='si'){ auth=dat[0].Credito };            
                const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/DTE/forma/pago/'+this.contrato);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    this.itemPAGO=[];
                    datos.forEach(element => {
                        if(element.CodigoBsale=='4'){
                            if(auth=='SI'){
                                this.itemPAGO.push({
                                    'id':element.CodigoBsale,
                                    'forma':element.Descripcion
                                })
                            }
                        } else {
                            this.itemPAGO.push({
                                'id':element.CodigoBsale,
                                'forma':element.Descripcion
                            })
                        }
                    });
                }                
            } catch (error) {
                console.log(error)
            }
        },
        // async cargaPagos(){
        //     const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/DTE/forma/pago/'+this.contrato);
        //     const datos=response.data;
        //     if (datos[0].existe=='si'){
        //         this.itemPAGO=[];
        //         datos.forEach(element => {
        //             this.itemPAGO.push({
        //                 'id':element.CodigoBsale,
        //                 'forma':element.Descripcion
        //             })
        //         });
        //     }
        // },
        async buscarSucursal_v2(){
            let res = '';
            try {
                const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/bodegas/'+this.contrato);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    datos.forEach(element => {
                        if(element.ID==this.nSucursal){
                            res = element.Nombre
                        };
                    });
                }                
            } catch (error) {
                console.log(error)
            };
            return res
        },
        // async buscarSucursal(){
        //     const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/usuarios/'+this.contrato+'/'+this.usu);
        //     const datos=response.data;
        //     if (datos[0].existe=='si'){
        //         if (datos[0].Sucursal!=0){
        //             this.nSucursal=datos[0].Sucursal;
        //             const response1 = await axios.get(process.env.VUE_APP_URL + '/api/inv/sucursales/'+this.contrato);
        //             const datos1=response1.data;
        //             if (datos1[0].existe=='si'){
        //                 datos1.forEach(element => {
        //                     if(element.ID==this.nSucursal){
        //                         this.txtSucursal=element.Nombre                            
        //                     }
        //                 });
        //             } else {  
        //                 this.txtSucursal='Sin Sucursal';
        //                 this.nSucursal=0;
        //             }
        //         } else {
        //             this.txtSucursal='Sin Sucursal';
        //             this.nSucursal=0;
        //         }
        //     } else { 
        //         this.txtSucursal='Sin Sucursal';
        //         this.nSucursal=0;
        //     }
        // },
        async fileCSV(){
            if (this.btnPrt===true){
                this.loading=true;
                const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/fileCsv/venta/'+this.contrato+'/'+this.OutID+'/'+this.entidad);
                const datos=response.data;
                //console.log(datos);
                if (datos=='si'){
                    this.descargaCSV=true;
                    this.rutaCSV=process.env.VUE_APP_URL + '/buzon/'+this.contrato+'/'+this.OutID+'.csv';
                }
                this.loading=false;
            }
        },
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        clsInput(){
            this.rut='';
            this.razon='';
            this.direccion='';
            this.comuna='';
            this.ciudad='';
            this.giro='';
            this.fecha='';
            this.hoy='';
            this.neto=0;
            this.iva=0;
            this.ajuste=0;
            this.total=0;
            this.impuesto='';
            this.dte='';
            this.pago='';
            this.items=[];
            this.btnPrt=false;
            this.html='';
            this.OutID=0;
            this.entidad='';
            this.descargaCSV=false;
            this.rutaCSV='';
            this.envio='';   
            this.nStock=0;
            this.txtStock='si';
            this.autoriza='NO';
            this.credito_dias='0';
            this.observa_cot='';
            this.conGuia='no';
            this.arrayGuia=[];

        },
        newVenta(){
            this.orden='';
            this.disabledOrden=false;
            this.filledOrden=false;
            this.btnGrabar=false;
            this.clsInput()
        },
        async verOrden(nOrden){
            this.clsInput();
            this.loading=true;
            const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/orden/'+this.contrato+'/'+nOrden);
            const datos=response.data;
            //console.log('data',datos);
            if (datos[0].existe=='si'){ 
                this.disabledOrden=true;
                this.filledOrden=true;
                this.btnGrabar=true;
                this.entidad=datos[0].Entidad;
                this.rut=datos[0].Rut;
                this.razon=datos[0].Nombre;
                this.direccion=datos[0].Direccion;
                this.comuna=datos[0].Comuna;
                this.ciudad=datos[0].Ciudad;
                this.giro=datos[0].Observacion;
                this.observa_cot=datos[0].ObservaCot;
                this.fecha=datos[0].Fecha;
                this.hoy=datos[0].Hoy;
                this.autoriza=datos[0].Credito;
                if(datos[0].Dias!=null){
                    this.credito_dias=datos[0].Dias;
                } else { this.credito_dias=0 };
                this.impuesto=datos[0].Iva;
                await this.cargaPagos(this.rut);
                this.items=[];
                let nNeto=0;
                let nTotal=0;
                //llenar array stock
                this.skuStock=[];
                for (let i = 0; i < datos.length; i++) {
                    const el = datos[i];
                    const found = this.skuStock.find(res => res.sku==el.Sku);
                    if(!found){
                        let tot = await this.totalStock(this.idLocal,el.Sku);
                        this.skuStock.push({
                            'bodega':parseInt(this.idLocal),
                            'sku':el.Sku,
                            'cantidad':parseFloat(tot)
                        })
                    }
                };
                //console.log(this.skuStock);
                let skuPrincipal=[];
                let skuResto=[];
                for (let index = 0; index < datos.length; index++) {
                    const element = datos[index];
                    //calcular el neto del precio
                     let imp=1+(this.impuesto/100);
                     let unidad=element.Precio/imp;

                    const tMedida=await this.searchMedida(element.Sku);
                    if(parseInt(element.Bodega)==parseInt(this.idLocal)){
                        skuPrincipal.push({
                            'codigo':element.Sku,
                            'producto':element.Producto,
                            'precio':new Intl.NumberFormat("de-DE").format(parseFloat(element.Precio)),
                            'unitario':element.Precio,
                            'cantidad':new Intl.NumberFormat("de-DE").format(parseFloat(element.Cantidad)),
                            'valorCantidad':element.Cantidad,
                            'medida':tMedida,
                            'neto':unidad,
                            //'bodega':element.Bodega,
                            'bodega':this.idLocal,
                            'stock':''
                        });
                    } else {
                        skuResto.push({
                            'codigo':element.Sku,
                            'producto':element.Producto,
                            'precio':new Intl.NumberFormat("de-DE").format(parseFloat(element.Precio)),
                            'unitario':element.Precio,
                            'cantidad':new Intl.NumberFormat("de-DE").format(parseFloat(element.Cantidad)),
                            'valorCantidad':element.Cantidad,
                            'medida':tMedida,
                            'neto':unidad,
                            //'bodega':element.Bodega,
                            'bodega':this.idLocal,
                            'stock':''
                        });
                    }
                    // this.items.push({
                    //     'codigo':element.Sku,
                    //     'producto':element.Producto,
                    //     'precio':new Intl.NumberFormat("de-DE").format(parseFloat(element.Precio)),
                    //     'unitario':element.Precio,
                    //     'cantidad':new Intl.NumberFormat("de-DE").format(parseFloat(element.Cantidad)),
                    //     'valorCantidad':element.Cantidad,
                    //     'medida':tMedida,
                    //     'neto':unidad,
                    //     //'bodega':element.Bodega,
                    //     'bodega':this.idLocal,
                    //     'stock':'no'
                    // });
                    nNeto=nNeto+(unidad*parseFloat(element.Cantidad));
                    nTotal=nTotal+parseFloat(element.Total)
                };
                //concatenamos los array
                this.items=skuPrincipal.concat(skuResto);

                //si el usuario es admin no se verifica el stock x local
                if(this.usu.toUpperCase()!='ADMIN'){
                    //verificamos stock del array
                    for (let ii = 0; ii < this.items.length; ii++) {
                        const dato = this.items[ii];
                        const conStock = await this.totalStock_v1(this.idLocal,dato.codigo,dato.valorCantidad);
                        this.items[ii].stock=conStock;
                        if(conStock!='si'){
                            this.txtStock='no'
                        }
                    }
                } else {
                    this.d_stock=true;
                    for (let ii = 0; ii < this.items.length; ii++) {
                        const dato = this.items[ii];
                        const conStock = 'si';
                        this.items[ii].stock=conStock;
                        if(conStock!='si'){
                            this.txtStock='no'
                        }
                    }
                }
                // totales
                let nIva=(nNeto*parseFloat(datos[0].Iva))/100;
                let nAjuste=nTotal-(nNeto+nIva);
                this.neto=parseFloat(nNeto).toFixed(2);
                this.iva=parseFloat(nIva).toFixed(2);
                this.ajuste=parseFloat(nAjuste).toFixed(2);
                this.total=nTotal;
                //verificar si la orden tiene guias de despacho
                this.conGuia = await this.verificaGuia(nOrden);
                //console.log(this.conGuia, this.arrayGuia)
            } else {
                this.Msg='Orden no Existe o con DTE'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
            this.loading=false;
        },
        async verificaGuia(nOrden){
            let result = 'no';
            try {
                const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/verify/guide/'+ this.contrato +'/'+ nOrden);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    this.arrayGuia = datos[0];
                    result = 'si';
                } else { 
                    result = 'no' 
                }
            } catch (error) {
                console.log(error);
                result = 'no'
            }
            return result
        },
        async searchMedida(mSku){
            try {
                const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/unidad/medida/'+ this.contrato +'/'+ mSku);
                const datos=response.data;
                if (datos[0].existe=='si'){ 
                    return datos[0].Medida;
                } else { return 'Sin Medida' }
            } catch (error) {
                return 'Sin Medida'
            }
        },
        async totalStock(b,s){
            const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/stock/bodegas/producto/total/'+ this.contrato +'/'+ b + '/' + s);
            const datos=response.data;
            if (datos[0].existe=='si'){ 
                 return datos[0].Total;
            } else { return 0 }
        },
        async totalStock_v1(store,code,quanty){ //store=bodega ; code=sku ; quanty=cantidad
            let result='no';
            if(this.skuStock.length!=0){
                for (let index = 0; index < this.skuStock.length; index++) {
                    const element = this.skuStock[index];
                    if(parseInt(element.bodega)==parseInt(store) && element.sku==code){
                        if(parseFloat(quanty)>parseFloat(element.cantidad)){
                            //no queda stock
                            this.skuStock[index].cantidad=0;
                            result='no';
                        } else {
                            const saldo=parseFloat(element.cantidad)-parseFloat(quanty);
                            this.skuStock[index].cantidad=parseFloat(saldo);
                            result='si';
                        }
                    }
                }
            }
            return result
        },
        async enviarDTE(selectDTE,selectPago){
            // validar tipo de sistema
            var sigla=this.contrato.substring(0,3);
            //console.log(sigla);
            if (sigla=='INV') {
                await this.inDTE(selectDTE,selectPago);
            } else {
                if (sigla=='NDV'){
                    await this.outDTE(selectDTE,selectPago);
                } else {
                    this.Msg='Error de Contrato'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }
            }
        },
        async outDTE(selectDTE,selectPago){
            if (parseFloat(this.total)!=0){
                if (selectDTE!=0 && selectPago!=0){
                    if(this.txtStock!="si"){
                        this.Msg='Productos sin Stock';
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                    } else {
                        this.loading=true;
                        this.btnGrabar=false;
                        this.OutID=0;
                        //obtener ID nuevo del dte
                        const params={
                            'contrato':this.contrato,
                            'orden':this.orden,
                            'estado':'V',
                            'usuario':this.usu
                        };
                        try {
                            const response = await axios.post(process.env.VUE_APP_URL + '/api/inv/DTE/id/new/v2/',params);
                            const res = response.data;
                            if (res=='si'){
                                const response1 = await axios.get(process.env.VUE_APP_URL + '/api/inv/DTE/id/new/v2/'+this.contrato+'/'+this.orden+'/'+this.usu);
                                const res1 = response1.data;
                                if (res1[0].existe=='si'){
                                    this.OutID=parseFloat(res1[0].Folio); 
                                }                            
                            };
                            if (this.OutID!=0){
                                //console.log(this.OutID);
                                //confirmar que no existe en DTE
                                const response2 = await axios.get(process.env.VUE_APP_URL + '/api/inv/DTE/one/'+this.contrato+'/'+this.OutID);
                                const res2 = response2.data;
                                if (res2[0].existe=='no'){ 
                                    this.html=process.env.VUE_APP_URL + '/api/inv/prt/venta/v80/'+this.contrato+'/'+this.OutID+'/'+this.entidad;
                                    const paramsDTE={
                                        'contrato':this.contrato,
                                        'numero':this.OutID,
                                        'tipoDte':selectDTE,
                                        'tipoPago':selectPago,
                                        'neto':this.neto,
                                        'iva':this.iva,
                                        'total':this.total,
                                        'fecha':this.hoy,
                                        'vencimiento':this.vencimiento,
                                        'orden':this.orden,
                                        'rutaPDF':this.html,
                                        'rutaXML':this.html,
                                        'usuario':this.usu,
                                        'sucursal':this.nSucursal,
                                        'oficina':this.office,
                                    };     
                                    await this.saveDTE(paramsDTE);
                                } else {
                                    this.Msg='No Existen Folios'
                                    this.msgbox=true;
                                    this.color='red';
                                    this.showSnack();
                                }
                            } else {
                                this.Msg='Folio No Asignado'
                                this.msgbox=true;
                                this.color='red';
                                this.showSnack();
                            }
                        } catch (error) {
                            console.log(error)
                        }
                    }
                } else {
                    this.Msg='Seleccione DTE y Pago'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }
            } else {
                this.Msg='Sin Productos'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
            this.loading=false;
        },
        async inDTE(selectDTE,selectPago){
            if(this.nSucursal!=0){
                if(this.txtStock!="si"){
                    this.Msg='Productos sin Stock';
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                } else {
                    this.loading=true;
                    if (selectDTE!=0 && selectPago!=0){                
                        // obtenemos el token del contrato
                        let valToken='';
                        const resToken = await axios.get(process.env.VUE_APP_URL + '/api/inv/token/access/'+this.contrato);
                        const respuesta = resToken.data;
                        if (respuesta[0].existe=='si'){ 
                            valToken=respuesta[0].Token;
                        } else { console.log('Token Invalido') }
                        // fecha cotizacion formato Bsale
                        let intFecha=Date.parse(this.fecha);
                        let strFecha=intFecha.toString();
                        strFecha=strFecha.substring(0,10);
                        const numFecha=parseFloat(strFecha);
                        // fecha actual formato Bsale
                        let intHoy=Date.parse(this.hoy);
                        let strHoy=intHoy.toString();
                        strHoy=strHoy.substring(0,10);
                        const numHoy=parseFloat(strHoy);
                        // fecha vencimiento formato Bsale
                        let intVence=Date.parse(this.vencimiento);
                        let strVence=intVence.toString();
                        strVence=strVence.substring(0,10);
                        const numVence=parseFloat(strVence);
                        // creamos json Bsale
                        let pCliente={
                            "code": this.rut,
                            "city": this.ciudad,
                            "company": this.razon,
                            "municipality": this.comuna,
                            "activity": this.giro,
                            "address": this.direccion
                        };
                        let pDetalle=[];
                        this.items.forEach(element => {
                            pDetalle.push({
                                //"variantId":1,
                                "netUnitValue":parseFloat(element.neto).toFixed(4),
                                "quantity": parseFloat(element.valorCantidad).toFixed(4),
                                //"taxId": "[1,2]",
                                "comment": element.producto,
                                //"discount": 0,
                                "taxes": [
                                    {
                                    "code": 14,
                                    "percentage": 19
                                    }
                                ]
                            })        
                        });
                        let pPago=[
                            {
                                "paymentTypeId": selectPago,
                                "amount": this.total,
                                "recordDate": numHoy
                            }
                        ];
                        let myReason = '';
                        this.observa_cot = this.observa_cot.trim();
                        if(this.observa_cot!=''){
                            this.observa_cot = this.observa_cot.toUpperCase()
                            if(this.observa_cot.substring(0,2)=='OC'){
                                myReason = this.observa_cot
                            } else {
                                myReason = "OC " + this.orden
                            }
                        } else {
                            myReason = "OC " + this.orden
                        }
                        let pReferencia=[
                            {
                                "number": this.orden,
                                "referenceDate": numFecha,
                                "reason": myReason,
                                "codeSii": 801
                            }
                        ];
                        //console.log(selectDTE);
                        var emitDTE;
                        if(selectDTE!=22){ // factura
                            if(this.conGuia=='si'){ 
                                this.d_guia = true;
                                //cambia los datos de la referencia
                                // fecha cotizacion formato Bsale
                                let intFecha_guia=Date.parse(this.arrayGuia.Fecha);
                                let strFecha_guia=intFecha_guia.toString();
                                strFecha_guia=strFecha_guia.substring(0,10);
                                const numFecha_guia=parseFloat(strFecha_guia);
                                pReferencia=[
                                    {
                                        "number": this.arrayGuia.Numero,
                                        "referenceDate": numFecha_guia,
                                        "reason": myReason,
                                        "codeSii": 52
                                    }
                                ];
                            };
                            emitDTE=
                            {
                                "documentTypeId": selectDTE,
                                "officeId": parseInt(this.office),
                                "emissionDate": numHoy,
                                "expirationDate": numVence,
                                "declareSii": 1,
                                //"priceListId": 18,
                                "client": pCliente,
                                "details": pDetalle,
                                "payments": pPago,
                                "references": pReferencia
                            }
                        } else { // boleta
                            emitDTE=
                            {
                                "documentTypeId": selectDTE,
                                "officeId": parseInt(this.office),
                                "emissionDate": numHoy,
                                "expirationDate": numHoy,
                                "declareSii": 1,
                                //"priceListId": 18,
                                "details": pDetalle
                                //"payments": pPago,
                            }
                        }
                        // consumir api Bsale para crear DTE
                        let resDTE =[];
                        //console.log(emitDTE);
                        try {
                            const response = await axios.post('https://api.bsale.cl/v1/documents.json', emitDTE , {headers : {"Accept":"application/json","content-type":"application/json","access_token":valToken}});
                            resDTE = response.data;
                        } catch (error) {
                            console.log (error);
                        }
                        if (resDTE.length!=0){
                            this.envioDTE(this.envio,resDTE.urlPdf,resDTE.urlXml);
                            this.html=resDTE.urlPdf;
                            this.btnPrt=true;
                            this.btnGrabar=false;
                            this.OutID=0;
                            this.OutID=resDTE.number;                    
                            const paramsDTE={
                                'contrato':this.contrato,
                                'numero':resDTE.number,
                                'tipoDte':selectDTE,
                                'tipoPago':selectPago,
                                'neto':this.neto,
                                'iva':this.iva,
                                'total':this.total,
                                'fecha':this.hoy,
                                'vencimiento':this.vencimiento,
                                'orden':this.orden,
                                'rutaPDF':resDTE.urlPdf,
                                'rutaXML':resDTE.urlXml,
                                'usuario':this.usu,
                                'sucursal':this.nSucursal,
                                'oficina':this.office,
                            };
                            await this.saveDTE(paramsDTE);
                        } else {
                            this.Msg='DTE no enviado'
                            this.msgbox=true;
                            this.color='red';
                            this.showSnack();
                        }
                        //console.log(res);
                    } else {
                        this.Msg='Seleccione DTE y Pago'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                    }
                    this.loading=false;
                }
            } else {
                this.Msg='Venta Sin Sucursal'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
        },
        async saveDTE(parametrosDTE){
            const response = await axios.post(process.env.VUE_APP_URL + '/api/inv/v2/DTE/nueva',parametrosDTE);
            const datos=response.data;
            //console.log(parametrosDTE);
            if (datos=='si'){
                await this.updateReferencia();
            } else {
                this.Msg='DTE sin registro Local'
                this.msgbox=true;
                this.color='warning';
                this.showSnack();
            }
        },
        async updateReferencia(){
            var parametros0={
                'numero': this.OutID,
                'bodega':this.idLocal
            };
            const response0 = await axios.put(process.env.VUE_APP_URL + '/api/inv/referencia/actualiza/'+this.contrato+'/'+this.orden+'/'+this.entidad,parametros0);
            const datos0=response0.data;
            //console.log(datos0);
             if (datos0=='si'){
                if(this.conGuia=='no'){
                    await this.UpdateStock();
                } else {
                    this.btnPrt=true;
                    this.Msg='DTE exitoso';
                    this.msgbox=true;
                    this.color='success';
                    this.showSnack();
                }
            } else {
                this.Msg='DTE enviado sin Referencia';
                this.msgbox=true;
                this.color='warning';
                this.showSnack();
             }
        },
        async UpdateStock(){
            let t=0;
            if (this.items.length!=0) {
                for (var i = 0; i < this.items.length; i++) {
                    var parametros1={
                            'contrato': this.contrato,
                            'sku': this.items[i].codigo,
                            'cantidad': this.items[i].valorCantidad,
                            'signo': '0-',
                            'bodega': this.items[i].bodega,
                            'usuario': this.usu,
                            'observa': '',
                            'origen':'Venta'};
                    const response1 = await axios.post(process.env.VUE_APP_URL + '/api/inv/stock',parametros1);
                    const datos1=response1.data;
                    if (datos1=='si'){ t++ }
                };
                if (t!=this.items.length) {
                    this.Msg='DTE enviado sin actualizar Stock';
                    this.msgbox=true;
                    this.color='warning';
                    this.showSnack();
                } else {
                    this.btnPrt=true;
                    this.Msg='DTE exitoso';
                    this.msgbox=true;
                    this.color='success';
                    this.showSnack();
                }
            }
        },
        async envioDTE(datoEnvio,datoPDF,datoXML){
            if (datoEnvio.trim()!=''){
                let InstFormData = new FormData();
                InstFormData.append('email' , datoEnvio);
                InstFormData.append('pdf' , datoPDF);
                InstFormData.append('xml' , datoXML);
                const response= await axios.post(process.env.VUE_APP_URL + '/api/server/sender', InstFormData , {headers : {'content-type': 'multipart/form-data'}});
                //console.log(response.data)
            }
        },
    }
}
</script>